.inventory-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
}

.inventory-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.inventory-subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}

.inventory-button {
  margin-right: 10px;
}

.inventory-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

