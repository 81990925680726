.reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
  }
  
.reports-section {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;
}

.reports-subtitle {
font-size: 18px;
font-weight: bold;
margin-right: 20px;
}

.reports-button {
margin-right: 10px;
}

.reports-title {
font-size: 24px;
font-weight: bold;
margin-bottom: 10px;
}

  