.navigation-bar {
  display: flex;
  position: fixed;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EDECED;
  background-color: white;
  z-index: 100;
}

.navigation-links {
  display: flex;
}

.navigation-logo {
  margin-left: 20px;
  width: 150px;
}

.navigation-signout {
  text-decoration: underline;
}

.navigation-item {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}
.navigation-item a {
  font-size: 14px;
}
.navigation-item-supply-chain {
  background-color:#e9e6fb;
}
.navigation-item-customer-success {
  background-color:#d7e5d2;
}