.draftorder-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 60px;
}

.draftorder-form {
  display: flex;
  width: 250px;
  flex-direction: column;
}

.draftorder-title {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: bold;
}

.draftorder-input {
  width: 250px !important;
}

.draftorder-section {
  margin-bottom: 20px;
}

.draftorder-button {
  margin-bottom: 40px;
}

.draftorder-textarea {
  width: 550px;
}