.warehouse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  margin-top: 60px;
}

.warehouse-stage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 85px;
  align-items: center;
}

.warehouse-title {
  font-size: 22px;
  margin-bottom: 10px;
  justify-content: center;
}

.warehouse-input-name {
  width: 200px !important;
}

.warehouse-header-item-name {
  justify-content: center;
}

.warehouse-order-number {
  text-align: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 5px;
}

.warehouse-button {
  display: flex;
  font-size: 20px !important;
  margin-top: 10px;
}

.warehouse-xs-button {
  width: 75px;
  height: 44px;
}

.warehouse-sm-button {
  width: 90px;
  height: 44px;
}

.warehouse-med-button {
  width: 140px;
}

.warehouse-stage-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #dee2e6;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}

.warehouse-stage-img-item {
  justify-content: space-between;
  padding: 2px 4px;
}

.warehouse-stage-item-verified {
  border: 2px solid green;
  color: green;
}

.warehouse-stage-item-number {
  font-size: 20px;
  margin-right: 10px;
}

.warehouse-stage-item-label {
  font-size: 20px;
  font-weight: bold;
}

.warehouse-stage-item-img-label {
  font-size: 16px;
}

.warehouse-footer {
  justify-content: center;
  font-size: 20px;
  border-top: 2px solid #dee2e6;
  padding: 5px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-color: white;
}

.warehouse-header-item-title {
  font-size: 14px;
  justify-content: center;
}

.warehouse-header {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}

.warehouse-header-item {
  display: flex;
  flex-direction: column;
  border: 2px solid #dee2e6;
  padding: 2px 2px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  width: 50%;
}

.warehouse-header-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.warehouse-header-item-number {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 15px;
  border: 2px solid black;
  font-weight: bold;
  width: 28px;
  justify-content: center;
}

.warehouse-webcam-img {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.warehouse-webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warehouse-bom-container {
  display: flex;
  justify-content: center;
}

.warehouse-bom-input {
}

.warehouse-bom-item {
  display: flex;
  flex-direction: column;
}

.warehouse-bom-img {
  width: 320px;
}

.warehouse-bom-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.warehouse-bom-input {
  font-size: 40px !important;
  width: 100px !important;
  margin: 0 auto;
  text-align: center;
}

.warehouse-bom-order-input {
  font-size: 30px !important;
  width: 250px !important;
  height: 50px !important;
}

.warehouse-error {
  color: red;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

.warehouse-scanner-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.warehouse-scanner {
  width: 100%;
  height: 350px;
}

.warehouse-webcam-img {
  margin-top: -6px;
}

.warehouse-bom-done {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warehouse-bom-done-text {
  font-size: 20px;
}

.warehouse-stage-pick-label {
  margin-right: 4px;
}

.warehouse-warning {
  text-align: center;
}

.warehouse-pick-item {
  display: flex;
  align-items: center;
}

.warehouse-stage-item-location {
  margin-right: 4px;
}
