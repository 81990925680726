.upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-file {
  width: 400px !important;
}

.custom-file-input {
  cursor: pointer;
}

.upload-done {
  color: green;
  font-weight: bold;
  margin-bottom: 5px;
}
