thead th { position: sticky; top: 0; }

.loading-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
}

.loading {
  width: 400px;
}

.loading-title {
  font-size: 20px;
  font-weight: bold;
  color: #223662;
}

.orders-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.orders-allocation-mode {
  border: 2px solid orange !important;
}

.orders-bar {
  display: flex;
  justify-content: space-between;
}

.orders-content {
  display: flex;
  flex-direction: column;
  margin-top: 155px;
}

.orders-header {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  margin-top: 55px;
  z-index: 100;
}

.orders-sortable-header {
  color: navy;
  cursor: pointer;
}

.orders-filters-bar {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.orders-check {
  justify-content: center;
  display: flex !important;
  padding-left: 0 !important;
  align-items: center;
}

.orders-configuration {
  width: 200px;
}

.orders-filter-radio {
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.orders-filter-label {
  margin-bottom: 0;
}

.orders-warehouse__control {
  width: 150px;
  cursor: pointer !important;
}

.orders-warehouse__menu {
  width: 200px !important;
  cursor: pointer !important;
}
.orders-warehouse__option {
  cursor: pointer !important;
}

.orders-search-input {
  width: 220px !important;
}

.orders-table {
  margin-top: 5px;
}

.orders-table th {
  font-size: 12px !important;
  padding: 5px !important;
}
.orders-table td {
  font-size: 12px !important;
  padding: 5px !important;
}
.orders-table div {
  font-size: 12px;
}
.orders-table li {
  font-size: 12px !important;
}

.orders-pagination {
  margin-top: 10px;
  display: flex;
}

.orders-pagination-item {
  margin-right: 15px;
  cursor: pointer;
  text-decoration: underline;
  color: #007bff;
}

.orders-exit-allocation-mode {
  font-weight: bold;
  color: #F3A93B;
}

.orders-exit-allocation-mode:hover {
  cursor: pointer;
}

.popup {
  align-items: center;
  position: fixed;
  top: 10%;
  text-align: center;
  z-index: 3;
  color: #fff;
  width: 95%;
  height: 85%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.popup-content-header {
  justify-content: flex-end;
}

.popup-content {
  position: relative;
  background-color: #fefefe;
  border-radius: 4px;
  /* margin: 15% auto; */
  /* border: 1px solid #888; */
  /* width: 80%; */
  /* max-width: 975px; */
  max-width: 1000px;
  overflow: scroll;
  border: 1px solid #dee2e6;
  flex-direction: column;
  display: flex;
}

.popup-content-container {
  width: 100%;
  flex-direction: column;
  position: relative;
  color: black;
  padding: 30px;
}

.popup-btnclose {
  margin-left: auto;
}

.popup-closeicon {
  z-index: 2;
  opacity: 0.5;
  background: #0B329A;
  color: white !important;
  height: 30px;
  border-radius: 0;
  width: 30px;
  font-size: 25px;
  text-align: center;
  font-size: 20px;
  padding: 6px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 20px;
}

.popup-closeicon:hover {
  font-weight: bold;
  opacity: 1;
}

.popup-sm-btnclose {
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
}

.popup-sm-closeicon {
  background-color: #F7F7F7;
  padding: 0;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  font-size: 10px;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: none;
  color: #333435 !important;
  padding-left: 1px;
}

.popup-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.popup-button {
  margin-left: 10px;
}

@media only screen and (max-width: 1285px) {
  .orders-buttons {
    margin-right: 50px;
  }
}
