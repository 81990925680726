button.btn-dark-blue {
  background-color: #1D4A93;
  color: white;
}
button.btn-dark-blue:hover {
  color: white;
}

button.btn-dark-green {
  background-color: #145322;
  color: white;
}
button.btn-dark-green:hover {
  color: white;
}

button.btn-white {
  background-color: white;
  color: black;
  border: 1px solid #dee2e6;
}

.order-ignore {
  font-size: 18px !important;
  color: red;
  margin-top: 2px;
}
.order-ignore:hover {
  color: #b30000;
  cursor: pointer;
}

.order-arrow {
  font-size: 18px !important;
  color: #1D4A93;
  margin-top: 2px;
}
.order-arrow:hover {
  color: #0e2549;
  cursor: pointer;
}

.order-sku {
  width: 230px;
}
.order-desc {
  width: 200px !important;
  font-size: 10px !important;
  color: #000000 !important;
  padding: 5px !important;
}
.order-list {
  margin-left: -25px;
}
.order-priority button::after {
  margin-left: 20px;
}

.order-bom-sub {
  font-weight: bold;
}

.order-bom-missing {
  color: red;
}

.order-bom-dropship {
  color: #F6C244;
}

.order-boms {
  cursor: pointer;
}

.order-modal-content {
  display: flex;
  width: 500px;
  color: black;
}

.order-modal-header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.order-modal-header-title {
  font-size: 18px !important;
  font-weight: bold;
  margin-left: 15px;
}

.order-modal-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 14px !important;
}

.order-destination-label {
  font-size: 12px;
  font-weight: bold;
}

.order-modal-item-quantity {
  width: 30px;
}

.order-combine-with-input {
  width: 60px;
}

.order-modal-item-sku {
  width: 250px;
}

.order-modal-item-core-sku {
  width: 120px;
}

.order-modal-item {
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

.order-modal-delete-btn {
  width: 20px;
  height: 23px;
  padding: 0 !important;
  margin-left: 5px;
}

.order-modal-save-btn {
  margin-top: 20px;
  width: 100%;
}

.order-modal-add-btn {
  width: 21px;
  height: 23px;
  padding: 0 !important;
}

.order-final-bom {
  border: 2px solid #1D4A93 !important;
}

.spinner {
  width: 30px;
}

.fetch-rates-button {
  margin-right: 10px;
}