.signin-container {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signin-title {
  margin-bottom: 10px;
  font-size: 20px;
}
