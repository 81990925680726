.announcements-date-picker {
  width: 90px
}

.announcements-buttons {
  display: flex;
  align-items: center;
}

.announcements-field {
  display: flex;
  margin-right: 15px;
  align-items: center;
}

.announcements-field-label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.announcements-field-message {
  width: 350px !important;
}

.announcements-field-order {
  width: 100px !important;
}

.announcements-field-button {
  width: 180px;
}
.announcements-container {
  flex-direction: column;
  margin-top: 60px;
  margin-left: 25px;
  margin-right: 25px;
  width: 100%;
  height: 100vh;


}

.announcements-datepicker {
  width: 200px;
}

.announcements-bar {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.announcements-sortable-header {
  color: navy;
  cursor: pointer;
}

.announcements-filters-bar {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.announcements-check {
  justify-content: center;
  display: flex !important;
  padding-left: 0 !important;
  align-items: center;
}

.announcements-filter-radio {
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.announcements-filter-label {
  margin-bottom: 0;
}

.announcements-warehouse__control {
  width: 200px;
  cursor: pointer !important;
}

.announcements-warehouse__menu {
  width: 200px !important;
  cursor: pointer !important;
}
.announcements-warehouse__option {
  cursor: pointer !important;
}

.announcements-search-input {
  width: 220px !important;
  margin-top: 10px;
}

.announcements-table {
  margin-top: 15px;
}

.announcements-table th {
  font-size: 12px !important;
  padding: 5px !important;
}
.announcements-table td {
  font-size: 12px !important;
  padding: 5px !important;
}
.announcements-table div {
  font-size: 12px;
}
.announcements-table li {
  font-size: 12px !important;
}

.announcements-pagination {
  margin-top: 10px;
  width: 100%;
}

.announcements-pagination-item {
  margin-right: 15px;
  cursor: pointer;
  text-decoration: underline;
  color: #007bff;
}

@media only screen and (max-width: 1285px) {
  .announcements-buttons {
    margin-right: 50px;
  }
}
